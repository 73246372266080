import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <i>This content is stored in partials/canary-test-page.mdx</i>
    <h1 {...{
      "id": "this-is-a-plain-markdown-h1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#this-is-a-plain-markdown-h1",
        "aria-label": "this is a plain markdown h1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This is a plain markdown h1`}</h1>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed pulvinar libero. In hendrerit eget quam sit amet iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis quis ex risus. Etiam sit amet augue dolor. Aliquam eget tincidunt enim, laoreet pellentesque nunc. Curabitur nec metus pretium, mattis eros eget, lacinia neque. Quisque ut posuere ipsum. Nulla massa ex, accumsan sed sapien ut, scelerisque hendrerit augue.`}</p>
    <h2 {...{
      "id": "this-is-a-plain-markdown-h2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#this-is-a-plain-markdown-h2",
        "aria-label": "this is a plain markdown h2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This is a plain markdown h2`}</h2>
    <p>{`Suspendisse facilisis ligula quis ipsum faucibus, nec ultricies risus bibendum. Aenean convallis mattis porttitor. Quisque ultrices sit amet nunc non dapibus. Nunc malesuada, libero in rhoncus iaculis, ligula purus posuere ligula, ut aliquet quam massa eget tortor. Nam lectus lectus, porttitor ac iaculis feugiat, euismod iaculis ex.`}</p>
    <h3 {...{
      "id": "this-is-a-plain-markdown-h3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#this-is-a-plain-markdown-h3",
        "aria-label": "this is a plain markdown h3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This is a plain markdown h3`}</h3>
    <p>{`In nec malesuada ipsum. Aliquam nec risus sit amet odio ultricies posuere eu at mi. Maecenas molestie enim sit amet metus blandit malesuada. Duis condimentum egestas quam, pellentesque feugiat enim accumsan eget. Quisque facilisis est eget felis elementum, vel lobortis magna pulvinar. Cras porttitor felis ac hendrerit sollicitudin. Integer placerat lacus sed sollicitudin eleifend. Aliquam lacinia id felis sit amet tincidunt.`}</p>
    <h4 {...{
      "id": "this-is-a-plain-markdown-h4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#this-is-a-plain-markdown-h4",
        "aria-label": "this is a plain markdown h4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This is a plain markdown h4`}</h4>
    <p>{`Etiam faucibus congue varius. Duis blandit, est a commodo pretium, ligula lectus luctus sapien, ac condimentum quam magna vitae enim. Curabitur vitae mauris scelerisque, tristique felis eget, lobortis dolor. Pellentesque sodales felis at est pellentesque, in bibendum enim congue. Donec egestas sapien neque, eget iaculis erat tincidunt quis.`}</p>
    <h2 {...{
      "id": "this-h2eader-message-has-a-backtick-in-the-middle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#this-h2eader-message-has-a-backtick-in-the-middle",
        "aria-label": "this h2eader message has a backtick in the middle permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This h2eader message has a `}<inlineCode parentName="h2">{`backtick`}</inlineCode>{` in the middle`}</h2>
    <p>{`Aliquam accumsan neque et mi dapibus interdum. Etiam rhoncus quam sapien, id molestie mauris posuere ac. Suspendisse nec sapien vitae augue lacinia porta a nec sem. Fusce ultricies ornare augue eget facilisis. Nulla laoreet, orci non condimentum aliquet, lacus velit maximus ex, in elementum risus ligula eget libero. Vestibulum vehicula nibh orci, vitae ullamcorper sapien mollis ac.`}</p>
    <hr></hr>
    <p>{`This is a plain sentence. It has `}<a parentName="p" {...{
        "href": "/"
      }}>{`a local link`}</a></p>
    <p>{`This is a plain sentence. It has `}<a parentName="p" {...{
        "href": "https://www.fastly.com/"
      }}>{`an external link`}</a></p>
    <p>{`This is a sentence with a link to the `}<a parentName="p" {...{
        "href": "https://www.fastly.com/documentation/reference/api/"
      }}>{`Fastly API root`}</a></p>
    <p>{`This is a sentence with a link to the `}<a parentName="p" {...{
        "href": "https://www.fastly.com/documentation/reference/api/purging/"
      }}>{`Fastly API on purging`}</a></p>
    <hr></hr>
    <p>{`This is a local image`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/logo-red.png",
        "alt": "alt text of the internal image"
      }}></img>{`
(It should be the red-on-clear fastly logo)`}</p>
    <p>{`This is an external image`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.fastly.com/img/fastly_logo.png",
        "alt": "And this is the alt of the external image"
      }}></img>{`
(it should be the fastly wordmark, smallish, red on cleaar)`}</p>
    <p><em parentName="p">{`on guides-article `}<strong parentName="em">{`ONLY`}</strong>{` pages these should have a slight drop shadow`}</em></p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`This is a block quote`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`This is a list item inside a block quote`}</li>
      </ul>
    </blockquote>
    <hr></hr>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Im`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`A`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Table`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Col1.1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col1.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col1.3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Col2.1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col2.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col2.3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Col3.1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col3.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Col3.3`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      